.enquiry {
  align-items: center;
  text-align: center;
  margin-top: 70px;
  margin-bottom: 100px;
  font-family: myriad-font-ariel;
}
.enq1 {
  align-items: center;
  text-align: center;
}
.enq1 button {
  font-weight: 600;
  color: white;
  width: 80px;
  height: 35px;
  transition: 0.5s ease-in-out;
  transform: scale(1);
  background-color: #4b0150;
  border-radius: 10px;
}
.enq1 button:hover {
  transform: scale(1.2);
}
.enquiry h2 {
  font-family: myriad-font-ariel;
  font-weight: 700;
}

.enq2 {
  position: relative;
  width: 80%;
  height: 50px;
  overflow: hidden;
}
.enq2 input {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  border: none;
  outline: none;
}

.enq2 textarea {
  width: 100%;
  height: 100%;
  padding-top: 40px;
  border: none;
  outline: none;
  background: aqua;
  overflow: hidden;
  background: transparent;
}
.enq2 label {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid;
  pointer-events: none;
}
.enq2 label::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0px;
  bottom: 0px;
  border-bottom: 3px solid #2b3856;
  transform: translateX(-150%);
  transition: transform 0.4s ease;
}
.content-name {
  position: absolute;
  bottom: 5px;
  left: 0px;
  transition: all 0.5s ease;
}
.enq2 input:focus + .label-name .content-name,
.enq2 input:valid + .label-name .content-name {
  transform: translateY(-20px);
}
.enq2 input:focus + .label-name::before,
.enq2 input:valid + .label-name::before {
  transform: translateX(0);
}
.enq2 textarea:focus + .label-name .content-name,
.enq2 textarea:valid + .label-name .content-name {
  transform: translateY(-40px);
  z-index: 10;
}
ol li {
  justify-content: left;
}
.button1 {
  width: 80px;
  border: 2px, solid;
  background-color: #4b0150;
  transition: transform 0.8s;
}
