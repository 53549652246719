@import url("https://fonts.googleapis.com/css2?family=PT+Serif:wght@700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.intro {
  align-items: center;
}

.intro {
  padding-top: 10px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
  align-content: center;

  background-color: #4b0150;
}

.intro img {
  height: 200px;
  align-items: center;
  margin-top: 5px;
  object-fit: contain;
}

h4 {
  font-family: serif;
}
.p1 {
  padding: 20px;

  justify-content: center;
  align-items: center;
  background: transparent;
  color: white;
}
.p2 {
  background: transparent;
  color: white;
}
.p3 {
  justify-content: center;
  align-items: center;
  background: transparent;
  color: white;
}
.p4 {
  justify-content: center;
  align-items: center;
  background: transparent;
  color: white;
}
.p5 {
  justify-content: center;
  align-items: center;
  background: transparent;
  color: white;
}
.p6 {
  padding: 20px;

  justify-content: center;
  align-items: center;
  background: transparent;
  color: white;
}
h2 {
  font-family: "PT Serif", serif;
  word-spacing: 5px;
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  color: white;
}

::-webkit-scrollbar {
  background: linear-gradient(rgb(21, 194, 78), green);
}
::-webkit-scrollbar-thumb {
  background: #000435;
}
div img {
  transition: transform 0.8s;
}
.pd1 img {
  width: 200px;
}
.pd1 img:hover {
  transform: scale(1.2);
}
.pd1 h5 {
  font-weight: 700;
}
.intro h4 {
  font-weight: 700;
}
.backsuport .p1 {
  transition: transform 1s;
}
.backsuport .p1:hover {
  transform: scale(1.2);
}
.backsuport .p2 {
  transition: transform 1s;
}
.backsuport .p2:hover {
  transform: scale(1.2);
}
.backsuport .p3 {
  transition: transform 1s;
}
.backsuport .p3:hover {
  transform: scale(1.2);
}
.backsuport2 .p4 {
  transition: transform 1s;
}
.backsuport2 .p4:hover {
  transform: scale(1.2);
}
@media screen and (max-width: 480px) {
  .p1 {
    justify-content: center !important;
    align-items: start;
    margin-right: 30px !important;
    margin-bottom: 0px !important;
  }
  .pd1 img {
    width: 100% !important;
  }
}
