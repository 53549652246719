.footer a {
  text-decoration: none;
  color: white;
  padding: 10px;
}
.footer img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}
.footer {
  color: black;
  padding: 20px;
  text-align: justify;
  align-content: center;
  z-index: 1000;
  background-size: cover;
  background-repeat: no-repeat;
}
h1 {
  font-weight: 800;
}

Option:hover {
  background-color: blue;
}
