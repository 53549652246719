.carousel-inner {
  align-items: center;
  justify-content: center;
  position: relative;
  margin: auto;
  padding: auto;
}
.carousel-item img {
  width: 200px;
  height: 200px;
}
.carousel {
  position: relative;
  padding-inline: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.slideryoutube {
  text-decoration: none;
}
iframe {
  display: block;
}
.carousel-item {
  position: relative;
  justify-content: center;
  align-items: center;
  left: 40%;
  right: 40%;
}

.pp12 {
  background-color: darkblue;
  color: blue;
}
@media screen and (max-width: 450px) {
  .crs {
    width: 100% !important;
    padding: 10px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .crss iframe {
    right: 20px;
    left: 20;
    width: 75% !important;
    height: 100% !important;
    align-items: center !important;
    justify-content: center !important;
    margin-right: 50px !important;
    text-decoration: none;
  }
}
