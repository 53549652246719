.invoice .invoice-bottom {
  margin-bottom: 0px;
  display: flex;
  position: absolute;
  bottom: 0;
}
.invoice .datenow {
  position: absolute;
  right: 0;
}
.invoice {
  position: relative;
}
.main {
  min-height: 80vh;
}
.print .invicer {
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: auto;
}

.dropdown-list {
  list-style: none;
}
.note {
  margin-top: 80px;
  bottom: 0;
  padding: 20px;
  color: black;
}

.billLogo img {
  margin-left: 320px;
  margin-right: 320px;
  margin-bottom: 20px;
  text-align: center;
  align-items: center;
}
