.Bottom {
  border: 1px solid green;
  border-radius: 20px;
  align-items: center;
  align-content: center;
  text-align: center;
  padding-top: 10px;
  margin-left: auto;
  margin-right: auto;
  background-color: #4b0150;
  color: white;
}
#tred {
  align-items: right;
  text-decoration-style: solid;
  text-align: center;
}
li .social-icon {
  transition: transform 0.4s;
}

li .social-icon:hover {
  transform: scale(1.4);
}
.pd2 p {
  transition: transform 1s;
}

.pd2 p:hover {
  transform: scale(1.4);
}

.cont {
  font-weight: 900;
}
