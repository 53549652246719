@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Poppins:wght@300;400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* font-family: 'Poppins', sans-serif; */
/* font-family: 'Playfair Display', serif; */

/* //navbar css */
.navbar {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  line-height: 26px;
  text-transform: uppercase;
  box-shadow: 0 8px 6px -6px gray;
  --webkit-box-shadow: 0 8px 6px -6px gray;
  border-bottom: solid gray !important;
  position: relative;
}

.nav-link {
  font-weight: 300 !important;
}

.active {
  border-bottom: 2px solid;
}

.navbar-brand {
  font-weight: 700;
  font-family: "roboto", sans-serif;
  letter-spacing: 3px;
  padding: 0px;
  margin: 0px;
}
ul .nav-item {
  transition: transform 1s;
}
ul .nav-item:hover {
  transform: scale(1.2);
  color: blue;
  text-decoration-color: aqua;
  box-shadow: solid 1px #4b0150;
}

.search-form {
  margin-right: 10px;
  margin-top: 4px;
}

.search-form input {
  border: red;
  border-radius: 2px;
  border: 1px solid #4b0150;
}

.search-form button {
  background-color: #4b0150;
  border-radius: 0;
  color: white;
}

.pnf {
  display: flex;
  min-height: 60vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pnf-title {
  font-size: 100px;
  font-weight: 700;
}

.pnf-heading {
  font-weight: normal;
}

.pnf-btn {
  color: black;
  border: 1px solid black;
  text-decoration: none;
  padding: 10px;
  margin-top: 10px;
}

.pnf-btn:hover {
  background-color: black;
  color: white;
}

/* ========================================= */
/* =========contact us ========= */
.contactus {
  min-height: 70vh;
  text-align: justify;
  margin-left: auto;
  margin-right: auto;
}

.contactus div {
  padding: 30px;
}

.contactus img {
  height: 150px;
  width: 150px;
  object-fit: contain;
}

/* ==================== */
.product-link {
  text-decoration: none !important;
  color: black !important;
}

.cat-btn {
  padding: 40px 0px;
  font-size: 24px;
  text-transform: uppercase;
}

.cat-btn:hover {
  background-color: #434343;
  color: white;
}

/* ================
====dashboard
=================== */
.dashboard {
  margin-top: 100px !important;
}

.dashboard-menu h4 {
  background-color: #434343 !important;
  color: white;
  padding: 20px 0px;
}

.navbar-nav {
  margin-right: 40px;
  margin-left: 40px;
}

#ilink {
  margin-bottom: 10px;
  margin-top: 10px;
}

#contact {
  margin-top: 15px;
  margin-bottom: 10px;
}

.form-select1 {
  color: blue;
  text-justify: auto;
  width: auto;
}

.policy {
  padding: auto;
  min-height: 50vh;
  margin: 20px 10px 40px 10px;
}

.Terms {
  text-align: justify;
  margin: 30px 10px 10px 40px;
  min-height: 60vh;
}

.blog img {
  height: 200px;
  width: 300px;
}

.vedeo {
  margin-top: 20px;
  margin-bottom: 20px;
}

h1 {
  color: black;
  font-size: 30px;
}

ul {
  list-style-type: none;
}

.intro div {
  padding: 20px;
}
p {
  font-size: 14px;
}
