.banner-img {
  margin-top: 0px;
}

.home-page h1 {
  color: gray;
  font-family: "Playfair Display", serif;
}

.home-page {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.home-page .card {
  background-color: rgba(128, 128, 128, 0.097);
  width: 18rem;
}

.card-name-price {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.card-price {
  color: green;
  font-weight: bold;
}

.home-page button {
  border-radius: 5px;
  width: 50%;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 16px;
  font-weight: bold;
}

.pd1 {
  align-items: center;
  justify-content: center;
}

.button1 {
  width: 80px;
  bottom: 0;
  border: 2px, solid;
  position: absolute;
  background-color: #4b0150;

  color: white;
  transition: transform 0.8s;
}
.button1:hover {
  transform: scale(1.1);
  color: white !important;
}
.card-name-price .btn {
  width: 70%;
}
.home-page .card-text {
  color: rgb(90, 89, 89);
}
.home-page .btn2 {
  width: 150px;
  color: white;
  background-color: #4b0150;
  border-radius: 20%;
  align-items: center;
  justify-content: center;
  transition: transform 1s;
}
.btn2:hover {
  transform: scale(1.2);
  color: white !important;
}
.card {
  height: 350px;
  margin: 50px;
  overflow: hidden;
  display: inline-block;
}

.card img {
  transition: transform 0.5s;
}
.card img:hover {
  transform: scale(1.3);
}
.card-div {
  text-align: center;
  position: relative;
}
.card-div img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
}

/* ==================
filters
============== */
.filters h4 {
  margin-top: 100px;
  color: gray !important;
  border-bottom: 1px solid rgba(128, 128, 128, 0.337);
}

.ant-checkbox-wrapper:first-of-type {
  margin-left: 6px;
}

.ant-checkbox-wrapper {
  margin: 6px;
}

.ant-radio-wrapper {
  margin-bottom: 15px !important;
  margin-left: 10px;
}

.filters button {
  background-color: black;
  width: 100%;
  border: none;
  border-radius: 0;
  margin-top: 20px;
}

.loadmore {
  color: green;
  font-weight: bold;
  font-size: 20px !important;
}
.card-body button {
  margin: 10px;
}
